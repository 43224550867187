<template>
  <div style="margin-top: 40px;" class="fs-16 mx-1 mx-md-5" >
    <div class="row">
      <div class="col-md mb-1">
        <img class="footer-logo" src="~@/assets/images/icons/sk.svg" alt="резидент Сколково">
      </div>
      <div class="col-md text-md-center mb-1">
        <span style="opacity: 0.4">&copy; {{new Date().getFullYear()}}, ООО «Спейсер»</span>
      </div>
      <div class="col-md ml-md-auto mb-1" style="opacity: 0.3">
        <div>ИНН: 9703121728</div>
        <div>ОКВЭД: 63.11.1</div>
        <div>Адрес: г. Москва, Пресненская наб., д. 12</div>
        <div>Участник Сколково: 1126485</div>
      </div>
    </div>
    <div style="opacity: 0.3">Вся представленная на сайте информация носит исключительно информационный характер и не является публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса Российской Федерации</div>
  </div>

</template>

<script>
export default {}
</script>

<style>
.footer-2 {
  margin-inline: 64px;
}
.footer-logo {
  width: 100px;

}
</style>

<template>
  <div>
    <main-widget />

    <!-- <showAt breakpoint="small"> -->
      <footer-widget />
    <!-- </showAt> -->

    <b-modal
      id="connect-modal"
      v-model="modalsVisibility.callback"
      title=""
      centered
      hide-footer
    >
      <callback-modal-content />
    </b-modal>
  </div>
</template>

<script>
import MainWidget from "./widgets/Main";
import FooterWidget from "./widgets/Footer";

import CallbackModalContent from "./modals/callback";

export default {
  data() {
    return {
      listing: {
        last_update_time: -1,
        items: [],
      },
      modalsVisibility: {
        callback: false,
      },
    };
  },
  methods: {
    iconBySymbol(symbol) {
      //                console.log( `~@/assets/images/site/icons/q-${symbol.toLowerCase()}-icon.svg` );
      //                return require(`~@/assets/images/site/icons/q-${symbol.toLowerCase()}-icon.svg`);
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
  },
  components: {
    MainWidget,
    FooterWidget,

    CallbackModalContent,
  },
  watch: {},
  mounted() {
    const t = this

    this.$root.$on('closeModal', () => {
      t.closeModal();
    });
    //            this.$request.get("utils.listing").then( rsp => {
    //                this.listing.items = rsp.items;
    //                this.listing.last_update_time = rsp.last_update_time;
    //            });
  },
};
</script>

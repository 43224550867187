<template>
  <div>
    <showAt breakpoint="large">
      <div>
        <div class="q-section-block bg-white-color">
          <div class="d-flex">
            <div
              class="d-flex flex-column justify-content-around content-wrapper"
            >
              <div>
                <div
                  class="bg-gray badge-block animate__animated"
                  data-anime="animate__fadeInDown"
                >
                  <span
                    class="pre-title user-select-none"
                    style="color: #444444"
                    >Закрытая партнерская сеть
                  </span>
                </div>
                <h1
                  class="title mr-4 dark animate__animated user-select-none"
                  data-anime="animate__fadeInDown"
                >
                  Приводите клиентов <br />и<span
                    class="transform-text"
                    style="margin-left: 6px"
                    >зарабатывайте</span
                  >
                  вместе<br />
                  с крупными компаниями <br /><span class="transform-text"
                    >из любой точки мира</span
                  >
                </h1>
              </div>
              <b-button
                @click="showModal('callback')"
                class="animate__animated"
                data-anime="animate__fadeInDown"
              >
                Подключиться
              </b-button>
            </div>
            <div class="image-wrapper">
              <div
                class="showcase_block animate__animated"
                data-anime="animate__fadeInUp"
              >
                <b-img
                  fluid
                  src="~@/assets/images/site/v1/main/showcase_v3.png"
                  style="transform: scale(1.15, 1.15) translateY(-25px)"
                />
              </div>
            </div>
          </div>

          <hr class="light" />

          <div class="about-block">
            <div class="item bg-orange-gradient user-select-none">
              <b-img
                src="~@/@core/assets/images/site/v1/q-about-1.svg"
                alt="about-item"
                fluid
                class="mr-1"
              />
              <span>200 + офферов</span>
            </div>
            <div class="item bg-purple-gradient user-select-none">
              <b-img
                src="~@/@core/assets/images/site/v1/q-about-2.svg"
                alt="about-item"
                fluid
                class="mr-1"
              />
              <span>Для проверенных экспертов</span>
            </div>
            <div class="item bg-red-gradient user-select-none">
              <b-img
                src="~@/@core/assets/images/site/v1/q-about-3.svg"
                alt="about-item"
                fluid
                class="mr-1"
              />
              <span>Подробная статистика</span>
            </div>
          </div>
        </div>
        <div class="q-section-partners">
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/yandex.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/ozon.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/sber.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/samokat.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/alfa.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/tinkoff.png"
              alt="yandex"
              fluid
            />
          </div>
        </div>
      </div>
    </showAt>

    <showAt breakpoint="medium">
      <div>
        <div class="mt-1 q-section-block bg-white-color">
          <div class="d-flex flex-column">
            <div
              class="mb-2 d-flex flex-column justify-content-around content-wrapper"
            >
              <div>
                <div
                  class="bg-gray badge-block animate__animated"
                  data-anime="animate__fadeInDown"
                >
                  <span class="pre-title" style="color: #444444"
                    >Закрытая партнерская сеть</span
                  >
                </div>
                <h1
                  class="title dark animate__animated"
                  data-anime="animate__fadeInDown"
                >
                  Приводите клиентов <br />и<span
                    class="transform-text"
                    style="margin-left: 6px"
                    >зарабатывайте</span
                  >
                  вместе<br />
                  с крупными компаниями <br /><span class="transform-text"
                    >из любой точки мира</span
                  >
                </h1>
              </div>
            </div>
            <div>
              <div class="mb-4 image-wrapper">
                <div
                  class="showcase_block animate__animated"
                  data-anime="animate__fadeInUp"
                >
                  <b-img
                    fluid
                    src="~@/assets/images/site/v1/main/showcase_v3.png"
                    style="transform: scale(1.1, 1.15) translateY(-25px)"
                  />
                </div>
              </div>
              <b-button
                block
                @click="showModal('callback')"
                class="mb-0 animate__animated"
                data-anime="animate__fadeInUp"
              >
                Подключиться
              </b-button>
            </div>
          </div>

          <hr class="light" />

          <div class="d-flex flex-column about-block">
            <div class="item w-100 mb-1 bg-orange-gradient">
              <b-img
                src="~@/@core/assets/images/site/v1/q-about-1.svg"
                alt="about-item"
                fluid
                class="mr-1"
              />
              <span>200 + офферов</span>
            </div>
            <div class="item w-100 mb-1 bg-purple-gradient">
              <b-img
                src="~@/@core/assets/images/site/v1/q-about-2.svg"
                alt="about-item"
                fluid
                class="mr-1"
              />
              <span>Для проверенных экспертов</span>
            </div>
            <div class="item w-100 mb-1 bg-red-gradient">
              <b-img
                src="~@/@core/assets/images/site/v1/q-about-3.svg"
                alt="about-item"
                fluid
                class="mr-1"
              />
              <span>Подробная статистика</span>
            </div>
          </div>
        </div>
        <div class="d-flex q-section-partners">
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/yandex.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/ozon.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/sber.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/samokat.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/alfa.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/tinkoff.png"
              alt="yandex"
              fluid
            />
          </div>
        </div>
      </div>
    </showAt>

    <showAt breakpoint="small">
      <div>
        <div class="mt-1 q-section-block bg-white-color">
          <div class="d-flex flex-column">
            <div
              class="mb-2 d-flex flex-column justify-content-around content-wrapper"
            >
              <div>
                <div
                  class="bg-gray badge-block animate__animated"
                  data-anime="animate__fadeInDown"
                >
                  <span class="pre-title" style="color: #444444"
                    >Закрытая партнерская сеть</span
                  >
                </div>
                <h1
                  class="title dark animate__animated"
                  data-anime="animate__fadeInDown"
                >
                  Приводите клиентов <br />и<span
                    class="transform-text"
                    style="margin-left: 6px"
                    >зарабатывайте</span
                  >
                  вместе<br />
                  с крупными компаниями <br /><span class="transform-text"
                    >из любой точки мира</span
                  >
                </h1>
              </div>
            </div>
            <div>
              <div class="mb-2 image-wrapper">
                <div
                  class="showcase_block animate__animated"
                  data-anime="animate__fadeInUp"
                >
                  <b-img
                    fluid
                    src="~@/assets/images/site/v1/main/showcase_v3.png"
                    style="transform: scale(1.1, 1.15) translateY(-25px)"
                  />
                </div>
              </div>
              <b-button
                block
                @click="showModal('callback')"
                class="mb-0 animate__animated"
                data-anime="animate__fadeInUp"
              >
                Подключиться
              </b-button>
            </div>
          </div>

          <hr class="light" />

          <div class="d-flex flex-column about-block">
            <div class="item w-100 mb-1 bg-orange-gradient">
              <b-img
                src="~@/@core/assets/images/site/v1/q-about-1.svg"
                alt="about-item"
                fluid
                class="mr-1"
              />
              <span>200 + офферов</span>
            </div>
            <div class="item w-100 mb-1 bg-purple-gradient">
              <b-img
                src="~@/@core/assets/images/site/v1/q-about-2.svg"
                alt="about-item"
                fluid
                class="mr-1"
              />
              <span>Для проверенных экспертов</span>
            </div>
            <div class="item w-100 mb-1 bg-red-gradient">
              <b-img
                src="~@/@core/assets/images/site/v1/q-about-3.svg"
                alt="about-item"
                fluid
                class="mr-1"
              />
              <span>Подробная статистика</span>
            </div>
          </div>
        </div>
        <div class="d-flex q-section-partners">
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/yandex.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/ozon.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/sber.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/samokat.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/alfa.png"
              alt="yandex"
              fluid
            />
          </div>
          <div class="item-partner">
            <b-img
              src="~@/@core/assets/images/site/v1/partners/tinkoff.png"
              alt="yandex"
              fluid
            />
          </div>
        </div>
      </div>
    </showAt>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    showModal(type) {
      this.$parent.showModal(type);
    },
  },
  components: {},
  watch: {},
  mounted() {
    setTimeout(() => {
      window.scrollBy(0, 1);
    }, 600);
  },
};
</script>
